<template>
  <base-section id="user-config" space="0">
    <v-col xs="10" sm="8" md="6" class="mx-auto">
      <base-loading v-if="loadingContent" class="my-8" />
      <section v-else>
        <UserSettingsForm :userInfo="userInfo" />
        <v-divider class="my-4" />
        <div class="d-flex flex-column justify-space-between align-center my-2">
          <ChangePassword class="full-width" />
          <base-btn class="my-2" block variant="plain" :to="toAddresses">
            {{ $t("addresses") }}
          </base-btn>
          <DeleteAccountBtn class="my-2" block />
        </div>
      </section>
    </v-col>
  </base-section>
</template>

<script>
import { userCtrl } from "@/controllers";
import {
  userInfoStoreGetters,
  userInfoStoreActions,
} from "@/store/modules/userInfo/constNames";
import { userRoutesName } from "@/router/names";
import { getErrorPayloadForSnackbar } from "@/helpers";

export default {
  name: "UserSettingsSection",
  components: {
    UserSettingsForm: () =>
      import("@/components/userSettings/UserSettingsForm"),
    DeleteAccountBtn: () =>
      import("@/components/userSettings/DeleteAccountBtn"),
    ChangePassword: () =>
      import("@/components/userSettings/UserChangePassword.vue"),
  },
  provide: {
    heading: { align: "center" },
  },
  data() {
    return {
      userInfo: {},
      loadingContent: true,
    };
  },
  computed: {
    toAddresses: () => ({
      name: userRoutesName.ADDRESSES.name,
    }),
  },
  mounted() {
    const userId = this.$store.getters[userInfoStoreGetters.GET_USER_ID];
    userCtrl
      .getUserInfo(userId)
      .then(({ data: user }) => {
        this.$store.dispatch(userInfoStoreActions.SAVE_USER, user);
        const userInfo = this.$store.getters[userInfoStoreGetters.GET_USER];
        this.userInfo = { ...userInfo };
      })
      .catch((error) => {
        const errorPayload = getErrorPayloadForSnackbar(error);
        this.$store.commit("user/OPEN_SNACKBAR", errorPayload);
      })
      .finally(() => {
        this.loadingContent = false;
      });
  },
};
</script>
